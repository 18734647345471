import React from 'react'
import tw, { styled } from 'twin.macro'
import Fuse from 'fuse.js'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImg from 'gatsby-background-image-es5'
import { useDebouncedCallback } from 'use-debounce'
import ReactMarkdown from 'react-markdown'
import SetWidthSection from '../containers/SetWidthSection'
import Search from '../../components/Search'

const FAQ: React.FC = () => {
  const { faqData, fluidBanner, faqCopy } = query()

  const faqs = React.useMemo<FaqFields[]>(() => {
    return faqData.map((faq) => {
      return {
        id: faq.node.id,
        question: faq.node.question,
        answer: faq.node.answer.answer,
      }
    })
  }, [faqData])

  const faqFuse = React.useMemo<Fuse<FaqFields>>(() => {
    return new Fuse<FaqFields>(faqs, {
      keys: ['question', { name: 'answer', weight: 2 }],
    })
  }, [faqs])

  const [searchValue, setSearchValue] = React.useState<string>('')
  const [foundFaqIds, setFoundFaqIds] = React.useState<string[]>(
    faqs.map((f) => f.id),
  )
  const [loading, setLoading] = React.useState(false)

  const debounceChange = useDebouncedCallback((value: string) => {
    setSearchValue(value)
    setLoading(false)
  }, 500)

  React.useEffect(() => {
    if (searchValue !== '') {
      const foundFaqs = faqFuse.search(searchValue)
      setFoundFaqIds(foundFaqs.map((f) => f.item.id))
    } else {
      setFoundFaqIds(faqs.map((f) => f.id))
    }
  }, [searchValue])

  const handleSearchValueChange = (value: string) => {
    setLoading(true)
    debounceChange.callback(value)
  }

  const questionsAndAnswers = React.useMemo(
    () =>
      faqs.map((faq, key) => {
        const hide = !foundFaqIds.includes(faq.id)
        return (
          <QuestionAnswer
            key={key}
            question={faq.question}
            answer={faq.answer}
            hide={hide}
          />
        )
      }),
    [foundFaqIds],
  )

  if (faqs.length === 0) {
    return null
  }

  return (
    <FAQBG fluid={fluidBanner} aria-labelledby="faq-heading">
      <SetWidthSection>
        <FAQContainer id="faq">
          <FAQInnerContainer>
            <Header id="faq-heading">{faqCopy}</Header>
            <Search onChange={handleSearchValueChange} loading={loading} />
            <ListContainer>{questionsAndAnswers}</ListContainer>
          </FAQInnerContainer>
        </FAQContainer>
      </SetWidthSection>
    </FAQBG>
  )
}

const FAQBG = tw(
  BackgroundImg,
)`bg-fixed bg-center w-full top-0 absolute left-0`
const FAQContainer = tw.div`px-4`
const FAQInnerContainer = tw.div`bg-gradient-to-bl from-blue-100 to-white rounded-sm shadow-2xl border-4 border-blue-400 px-6 py-16 sm:px-12 md:p-24`
const Header = tw.h2`text-shadow-lg border-b-2 border-blue-600 w-3/5 sm:w-4/5 pb-6 mb-6 mx-auto lg:mb-12 text-center leading-normal font-heading text-blue-900 text-xl sm:text-3xl lg:text-5xl xl:leading-none`
const ListContainer = styled.dl`
  ${tw`mt-6 pt-6`};
  ${tw`md:grid md:grid-cols-12 md:gap-8 overflow-hidden transition-all duration-150 ease-in-out`};
`

const QuestionAnswer: React.FC<QuestionAnswerProps> = (props) => {
  return (
    <>
      <Question hide={props.hide}>{props.question}</Question>
      <Answer hide={props.hide}>
        <ReactMarkdown>{props.answer}</ReactMarkdown>
      </Answer>
    </>
  )
}

const Question = styled.dt<{ hide?: boolean }>`
  ${tw`overflow-hidden transition-all duration-100 ease-in-out`};
  ${tw`text-lg leading-6 font-bold text-blue-600 md:col-span-5`}
  ${(props) => (props.hide ? tw`h-0` : 'height: fit-content')};
  ${(props) => (props.hide ? null : tw`px-3 md:p-6`)};
`
const Answer = styled.dd<{ hide?: boolean }>`
  ${tw`overflow-hidden transition-all duration-100 ease-in-out`};
  ${tw`mt-2 md:mt-0 md:col-span-7 text-base leading-6 text-blue-900`};
  ${(props) => (props.hide ? tw`h-0 opacity-0 p-0` : 'height: fit-content')};
  ${(props) => (props.hide ? null : tw`pt-3 pb-12 px-3 md:p-6 opacity-100`)};
`

interface Data {
  allContentfulFaq: {
    edges: [
      {
        node: {
          answer: { answer: string }
          question: string
          id: string
        }
      }
    ]
  }
  contentfulImage: {
    image: {
      localFile: {
        childImageSharp: {
          fluid: unknown
        }
      }
    }
  }
}

const query = () => {
  const data = useStaticQuery<Data>(graphql`
    query {
      faqCopy: contentfulTextCopy(key: { eq: "Frequently Asked Questions" }) {
        value
      }
      allContentfulFaq {
        edges {
          node {
            answer {
              answer
            }
            question
            id
          }
        }
      }
      contentfulImage(key: { eq: "FAQ Background" }) {
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 4912, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
  `)
  return {
    faqCopy: data.faqCopy.value,
    faqData: data.allContentfulFaq.edges,
    fluidBanner: data.contentfulImage.image.localFile.childImageSharp.fluid,
  }
}

interface FaqFields {
  id: string;
  question: string;
  answer: string;
}

interface QuestionAnswerProps {
  question: string;
  answer: string;
  hide: boolean;
}

export default FAQ
