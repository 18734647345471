import React from 'react'
import { graphql, PageProps } from 'gatsby'
import MainLayout from '../layouts/MainLayout'
import MeetTheTeam from '../layouts/sections/MeetTheTeam'
import FAQ from '../layouts/sections/FAQ'
import Hero from '../layouts/sections/Hero'
import Services from '../layouts/sections/Services'
import Contact from '../layouts/sections/Contact'

const HomePage: React.FC<PageProps<Data>> = (props) => {
  return (
    <MainLayout
      fixed={true}
      phone={props.data.phone.value}
      address={props.data.address.value}
    >
      <Hero fixed={true} />
      <Services />
      <MeetTheTeam />
      <FAQ />
      <Contact
        phone={props.data.phone.value}
        address={props.data.address.value}
      />
    </MainLayout>
  )
}

export const query = graphql`
  query {
    address: contentfulSetting(key: { eq: "Address" }) {
      value
    }
    phone: contentfulSetting(key: { eq: "Phone" }) {
      value
    }
  }
`

interface Data {
  address: { value: string };
  phone: { value: string };
}

export default HomePage
