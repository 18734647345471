import React from 'react'
import tw from 'twin.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Search: React.FC<Props> = (props) => {
  const [value, setValue] = React.useState<string>('')

  React.useEffect(() => {
    props.onChange(value)
  }, [value])

  const handleChange = React.useCallback(
    (event) => {
      setValue(event.target.value)
    },
    [setValue],
  )

  return (
    <SearchContent id="Search">
      <SearchContainer>
        <SearchInnerContainer>
          <label htmlFor="search" className="sr-only">
            FAQ Search
          </label>
          <InputContainer>
            <IconContainer>
              {props.loading && (
                <FontAwesomeIcon icon={['fas', 'spinner']} spin />
              )}
              {!props.loading && <FontAwesomeIcon icon={['fas', 'search']} />}
            </IconContainer>
            <InputField
              role="searchbox"
              id="search"
              placeholder="How can we help?"
              type="search"
              onChange={handleChange}
            />
          </InputContainer>
        </SearchInnerContainer>
      </SearchContainer>
    </SearchContent>
  )
}

Search.defaultProps = {
  onChange: () => null,
  loading: false,
}

const SearchContent = tw.div`max-w-screen-xl mx-auto text-center`
const SearchContainer = tw.div`flex-1 flex items-center justify-center px-2`
const SearchInnerContainer = tw.div`max-w-lg w-full`
const InputContainer = tw.div`relative`
const IconContainer = tw.div`absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none`
const InputField = tw.input`block w-full pl-10 pr-4 py-3 shadow-md placeholder-gray-500 leading-5 transition ease-in-out duration-300`

interface Props {
  onChange: (string: string) => void;
  loading: boolean;
}

export default Search
