import React from 'react'
import tw from 'twin.macro'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import ReactMarkdown from 'react-markdown'

const TeamMembers: React.FC<{ members: Member[] }> = (props) => {
  const renderedTeamMembers = props.members.map((teamMember, key) => {
    return (
      <TeamMember key={key}>
        <TeamMemberImage
          fluid={teamMember?.node?.image?.localFile?.childImageSharp?.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
        />
        <TeamMemberInfo>
          <TeamMemberName>{teamMember?.node?.name}</TeamMemberName>
          <TeamMemberTitle>{teamMember?.node?.title}</TeamMemberTitle>
          {teamMember?.node?.description?.description && (
            <TeamMemberAbout>
              <ReactMarkdown>{teamMember?.node?.description?.description}</ReactMarkdown>
            </TeamMemberAbout>
          )}
        </TeamMemberInfo>
      </TeamMember>
    )
  })

  // Had to return it like this due to https://github.com/DefinitelyTyped/DefinitelyTyped/issues/41808
  return <>{renderedTeamMembers}</>
}

interface Member {
  node: {
    name: string
    title: string
    description: {
      description: string
    }
    image: {
      localFile: {
        childImageSharp: {
          fluid: unknown
        }
      }
    }
  }
}

interface Data {
  ourStaffCopy: {
    value: string
  }
  allContentfulTeamMember: {
    edges: Member[]
  }
}

const MeetTheTeam: React.FC = () => {
  const data = useStaticQuery<Data>(graphql`
    query {
      ourStaffCopy: contentfulTextCopy(key: { eq: "Our Staff" }) {
        value
      }
      allContentfulTeamMember {
        edges {
          node {
            name
            title
            description {
              description
            }
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  console.log('data', data)

  let teamMembers = null
  if (data.allContentfulTeamMember && data.allContentfulTeamMember.edges.length > 0) {
    teamMembers = (
      <TeamMembers members={data.allContentfulTeamMember.edges} />
    )
  }

  return (
    <Container id="team" aria-labelledby="team-heading">
      <InnerContainer>
        <Header id="team-heading">{data.ourStaffCopy.value}</Header>
        <TeamContainer>
          {teamMembers}
        </TeamContainer>
      </InnerContainer>
    </Container>
  )
}

const Container = tw.section`relative bg-white`
const InnerContainer = tw.div`text-center mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-16`
const Header = tw.h2`text-shadow-lg border-b-2 border-blue-600 sm:w-1/2 pb-6 mb-6 mx-auto lg:mb-12 text-center leading-9 font-heading text-blue-900 text-4xl sm:text-5xl sm:leading-10 lg:text-6xl lg:leading-none`
const TeamContainer = tw.div`flex flex-wrap -mx-4`
const TeamMember = tw.div`w-1/2 p-4 text-center lg:(w-1/3 text-left)`
const TeamMemberName = tw.h3`font-bold text-xl`
const TeamMemberImage = tw(Img)`w-3/5 mb-4 mx-auto lg:mx-0`
const TeamMemberAbout = tw.p`text-sm font-thin lg:text-base`
const TeamMemberInfo = tw.div`font-medium text-lg leading-6 space-y-1`
const TeamMemberTitle = tw.p`text-blue-600`

export default MeetTheTeam
